exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-case-studies-index-tsx": () => import("./../../../src/pages/case-studies/index.tsx" /* webpackChunkName: "component---src-pages-case-studies-index-tsx" */),
  "component---src-pages-case-studies-morph-tsx": () => import("./../../../src/pages/case-studies/morph.tsx" /* webpackChunkName: "component---src-pages-case-studies-morph-tsx" */),
  "component---src-pages-case-studies-nwc-tsx": () => import("./../../../src/pages/case-studies/nwc.tsx" /* webpackChunkName: "component---src-pages-case-studies-nwc-tsx" */),
  "component---src-pages-case-studies-thames-21-tsx": () => import("./../../../src/pages/case-studies/thames21.tsx" /* webpackChunkName: "component---src-pages-case-studies-thames-21-tsx" */),
  "component---src-pages-case-studies-wrt-tsx": () => import("./../../../src/pages/case-studies/wrt.tsx" /* webpackChunkName: "component---src-pages-case-studies-wrt-tsx" */),
  "component---src-pages-company-tsx": () => import("./../../../src/pages/company.tsx" /* webpackChunkName: "component---src-pages-company-tsx" */),
  "component---src-pages-demo-tsx": () => import("./../../../src/pages/demo.tsx" /* webpackChunkName: "component---src-pages-demo-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-pricing-tsx": () => import("./../../../src/pages/pricing.tsx" /* webpackChunkName: "component---src-pages-pricing-tsx" */),
  "component---src-templates-legal-tsx": () => import("./../../../src/templates/legal.tsx" /* webpackChunkName: "component---src-templates-legal-tsx" */)
}

